/* Direct API to openrouter that bypass the site server */
import LocalStorage from "./local_storage";
import APIDef from "../config/api_def";



import OpenAI from 'openai';
// import WS from './websocket';


class LLMCompletionStream {
    constructor(){
        this.openai = new OpenAI({
            baseURL: 'https://openrouter.ai/api/v1',
        //   apiKey: process.env.OPENROUTER_API_KEY
            apiKey: "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",
            dangerouslyAllowBrowser:'true',
            defaultHeaders: {
            // "HTTP-Referer":"https://github.com/alexanderatallah/openrouter-streamlit",
            "HTTP-Referer": "https://dev.entrosystem.com", // Optional, for including your app on openrouter.ai rankings.
            "X-Title": "entrosystem", // Optional. Shows in rankings on openrouter.ai.
            }
        });
        this.model_list = ["meta-llama/llama-3.1-8b-instruct:free", "meta-llama/llama-3.1-405b-instruct", "meta-llama/llama-3-70b-instruct", "mistralai/mixtral-8x22b-instruct", "google/gemini-flash-1.5", 
            "google/gemini-pro-1.5", "openai/gpt-4-turbo", "anthropic/claude-3.5-sonnet:beta", "openai/gpt-4o-mini", "openai/gpt-4o-2024-08-06", "cohere/command-r-03-2024", 
            "meta-llama/llama-3.1-8b-instruct" ];
        this.model_name = ["llama-3.1-8b-free", "llama-3.1-405b", "llama-3-70b", "mixtral-8x22b-instruct", "gemini-flash-1.5", 
            "gemini-pro-1.5", "gpt-4-turbo", "3.5-sonnet", "gpt-4o-mini", "gpt-4o", "command-r", 
            "llama-3.1-8b" ];

        this.prompt_type = {
            prompt:"prompt",
            message:"message"
        };

        this.AUTH_TOKEN = LocalStorage.Load(LocalStorage.FileList.token);
        this.url_parameter = new URLSearchParams({
            token:this.AUTH_TOKEN
        }).toString();
        this.address = APIDef.Address.completion + "/?" + this.url_parameter;
        // this.address = APIDef.Address.completion;
    }

    async* stream(model, message, prompt_type) {
        if (prompt_type = this.prompt_type.message){
            const completion = await this.openai.chat.completions.create({
                model: model,
                messages: [{ role: 'user', content: message }],
                stream: true
            });

            for await (const chunk of completion) {
                yield chunk;  // Yield each chunk as it comes
            }
        }else if (prompt_type = this.prompt_type.prompt){
            const completion = await this.openai.completions.create({
                model: model,
                prompt:message,
                stream: true
            });


            for await (const chunk of completion) {
                yield chunk;  // Yield each chunk as it comes
            }
        }

    }


    async* stream2(model, message, prompt_type){
        const body = {
            model:model,
            message:message,
            prompt_type:prompt_type,
        }
        // console.log(body);
        const packet = {
            method  : 'POST',
            headers : {'Content-Type': 'application/json',},
            // headers : {'Content-Type': 'text/plain',},
            body    : JSON.stringify(body),
            // model   : 'cors',
        }
        console.log("stream2", this.address);

        try{
            const reply = await fetch(this.address, packet);
            // console.log(reply);
            // console.log(await reply.text());

            if (reply.status === 200){
                const chunk_reader = reply.body.getReader() ;
                const decoder      = new TextDecoder('utf-8');
                while (true){
                    const {value, done} = await chunk_reader.read();   // has to use label value and done
                    if (done){break;}
                    yield decoder.decode(value);
                    // console.log(decoder.decode(value));
                }
            }else if (reply.status === 401){
                const errorMessage = await reply.text();
                yield(`Error: Server refused the request : ${errorMessage}`);
            }else{
                yield(`Error: Received unexpected status code : ${reply.status}`);
            }
        }catch(error){
            yield(`Error: Network error or issue with server fetch : ${error.message}`);
        }
    }








}
  
export default LLMCompletionStream;
























// const openai = new OpenAI({
//     baseURL: 'https://openrouter.ai/api/v1',
//   //   apiKey: process.env.OPENROUTER_API_KEY
//     apiKey: "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",
//     dangerouslyAllowBrowser:'true',
//   //   defaultHeaders: {
//   //     // "HTTP-Referer": $YOUR_SITE_URL, // Optional, for including your app on openrouter.ai rankings.
//   //     // "X-Title": $YOUR_SITE_NAME, // Optional. Shows in rankings on openrouter.ai.
//   //   }
//   });




// class LLMCompletionStream extends EventEmitter {
//   async streamCompletions(model, message) {
//     const completion = await openai.chat.completions.create({
//       model: model,
//       messages: [{ role: 'user', content: message }],
//       stream: true
//     });


//     for await (const chunk of completion) {
//       this.emit('data', chunk.choices[0]?.delta?.content || "");
//     }
//     this.emit('end');
//   }
// }

// export default LLMCompletionStream;









// import OpenAI from "openai"

// const openai = new OpenAI({
//   baseURL: "https://openrouter.ai/api/v1",
//   apiKey: $OPENROUTER_API_KEY,
//   defaultHeaders: {
//     // "HTTP-Referer": $YOUR_SITE_URL, // Optional, for including your app on openrouter.ai rankings.
//     // "X-Title": $YOUR_SITE_NAME, // Optional. Shows in rankings on openrouter.ai.
//   }
// })

// async function main() {
//   const completion = await openai.chat.completions.create({
//     model: "meta-llama/llama-3.1-8b-instruct",
//     messages: [
//       { role: "user", content: "Say this is a test" }
//     ],
//     stream: true,
//   })
//   for await (const chunk of completion) {
//     process.stdout.write(chunk.choices[0]?.delta?.content || "");
//   }
// //   console.log(completion.choices[0].message)
// }
// main()





// import OpenAI from 'openai';
// import EventEmitter from 'events';

// const openai = new OpenAI({
//   baseURL: 'https://openrouter.ai/api/v1',
//   apiKey: process.env.OPENROUTER_API_KEY
// });

// class CompletionStream extends EventEmitter {
//   async streamCompletions(model, message) {
//     const completion = await openai.chat.completions.create({
//       model: model,
//       messages: [{ role: 'user', content: message }],
//       stream: true
//     });

//     for await (const chunk of completion) {
//       this.emit('data', chunk.choices[0]?.delta?.content || "");
//     }
//     this.emit('end');
//   }
// }

// export default CompletionStream;





// import React, { useEffect, useState } from 'react';
// import CompletionStream from './streamCompletions';

// const ChatComponent = () => {
//   const [messages, setMessages] = useState([]);
//   const completionStream = new CompletionStream();

//   useEffect(() => {
//     completionStream.on('data', (newMessage) => {
//       setMessages((prevMessages) => [...prevMessages, newMessage]);
//     });

//     completionStream.on('end', () => {
//       console.log('Stream ended');
//     });

//     completionStream.streamCompletions('meta-llama/llama-3.1-8b-instruct', 'Say this is a test');

//     return () => {
//       completionStream.removeAllListeners();
//     };
//   }, []);

//   return (
//     <div>
//       {messages.map((message, index) => (
//         <div key={index} className={`chat_llm ${index % 2 === 1 ? 'chat_llm-text-alt' : 'chat_llm-text'}`}>
//           <div className='chat_llm_text'>
//             {message}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ChatComponent;
